var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"about_tab","role":"tabpanel","aria-labelledby":"about-tab"}},[_c('div',{staticClass:"about_tab_content"},[_c('Tables',{attrs:{"searchPlaceholder":_vm.$t('searchProductName'),"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.assignments},on:{"change:search":_vm.SET_SEARCH},scopedSlots:_vm._u([{key:"createdAt",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatter(new Date(item.createdAt)))+" ")]}},{key:"title",fn:function({ item }){return [_c('p',[_vm._v(" "+_vm._s(item.product.title)+" ")])]}},{key:"discount",fn:function({ item }){return [_c('h6',{staticClass:"text-info"},[_vm._v(_vm._s(item.discount)+"%")])]}},{key:"filePath",fn:function({ item }){return [_c('button',{staticClass:"btn btn-pill donate btn-xs mx-1",on:{"click":function($event){_vm.activePdf = _vm.$api.image(item.filePath);
            _vm.showActivePdf = true;}}},[_vm._v(" "+_vm._s(_vm.$t("viewFile"))+" ")]),_c('button',{staticClass:"btn btn-pill btn-dark btn-xs mx-1",on:{"click":function($event){_vm.$store.commit('SET_MAIL_DRAWER', {
              status: true,
              form: {
                to: item.user.email,
                bcc: '',
                subject: _vm.$t('aboutBoughtProduct', {
                  title: item.product.title,
                  typeTitle: _vm.formatter(new Date(item.createdAt)),
                }),
                content: '',
                files: [],
              },
            })}}},[_vm._v(" "+_vm._s(_vm.$t("sendMail"))+" ")])]}}])})],1),_c('Modal',{attrs:{"hide-footer":""},model:{value:(_vm.showActivePdf),callback:function ($$v) {_vm.showActivePdf=$$v},expression:"showActivePdf"}},[_c('iframe',{attrs:{"src":_vm.activePdf,"width":"100%","height":"1000","frameborder":"0"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
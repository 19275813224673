<template>
  <div
    class="tab-pane fade show active"
    id="about_tab"
    role="tabpanel"
    aria-labelledby="about-tab"
  >
    <div class="about_tab_content">
      <Tables
        :searchPlaceholder="$t('searchProductName')"
        @change:search="SET_SEARCH"
        :loading="isLoading"
        :headers="headers"
        :items="assignments"
      >
        <template #createdAt="{ item }">
          {{ formatter(new Date(item.createdAt)) }}
        </template>
        <template #title="{ item }">
          <p>
            {{ item.product.title }}
          </p>
        </template>
        <template #discount="{ item }">
          <h6 class="text-info">{{ item.discount }}%</h6>
        </template>
        <template #filePath="{ item }">
          <button
            class="btn btn-pill donate btn-xs mx-1"
            @click="
              activePdf = $api.image(item.filePath);
              showActivePdf = true;
            "
          >
            {{ $t("viewFile") }}
          </button>
          <button
            class="btn btn-pill btn-dark btn-xs mx-1"
            @click="
              $store.commit('SET_MAIL_DRAWER', {
                status: true,
                form: {
                  to: item.user.email,
                  bcc: '',
                  subject: $t('aboutBoughtProduct', {
                    title: item.product.title,
                    typeTitle: formatter(new Date(item.createdAt)),
                  }),
                  content: '',
                  files: [],
                },
              })
            "
          >
            {{ $t("sendMail") }}
          </button>
        </template>
      </Tables>
    </div>
    <Modal v-model="showActivePdf" hide-footer>
      <iframe
        :src="activePdf"
        width="100%"
        height="1000"
        frameborder="0"
      ></iframe>
    </Modal>
  </div>
</template>

<script>
import Tables from "../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import { Currency } from "gurhan/helpers/Currency";
import Modal from "../Shared/Modal/index.vue";
export default {
  components: { Tables, Modal },
  props: {
    user: {
      default: () => {},
    },
  },
  data() {
    return {
      activePdf: "",
      showActivePdf: false,
      search: "",
      page: 1,
      limit: 10,
      count: 10,
      headers: [
        {
          title: this.$t("productName"),
          value: "title",
          col: 12,
          md: 6,
          lg: 5,
          xl: 5,
        },
        {
          title: this.$t("uploadedAt"),
          value: "createdAt",
          col: 6,
          md: 3,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("file"),
          value: "filePath",
          col: 6,
          md: 9,
          lg: 9,
          xl: 9,
        },
      ],
      assignments: [],
      isLoading: false,
    };
  },
  computed: {
    getBoughtItems() {
      const userBoughtItems =
        this.$store.state.CustomerList.customerBoughtItemList.find(
          (c) => c.userId === this.user.id
        );
      if (userBoughtItems)
        return userBoughtItems.data.filter((d) =>
          d.title.toLowerCase().includes(this.search.toLowerCase())
        );
      return [];
    },
    getBoughtItemsLoading() {
      return this.$store.state.CustomerList.customerBoughtItemAreLoading;
    },
  },
  methods: {
    SET_SEARCH(search) {
      this.search = search;
    },
    Currency,
    formatter,
    async GET_LIST() {
      this.isLoading = true;
      const response = await this.$api.get(
        `Assignments?userId=${this.user.id}`
      );
      if (response.message === "OK") {
        this.assignments = response.data;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    if (!isNaN(Number(this.$route.query.page)))
      this.page = Number(this.$route.query.page);
    this.GET_LIST();
  },
};
</script>

<style></style>
